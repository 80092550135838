
export const localUrl = 'https://test.codsquad.net/'
export const serverUrl = 'https://apitest.codsquad.net/'


// export const localUrl = 'http://localhost:8080/'
// export const serverUrl = 'http://localhost:8000/'

export const version = '1.0.1';

export const currency = "$";